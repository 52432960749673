<template>
    <div class="pad-3">
      <div class="bb-container bb-container--lista">
        <h1 class="header-1">Have you seen a charge from HelloBB?</h1>
        <div class="post-entry">
            <p>HelloBB is a platform that allows individuals to create private lists and receive monetary contributions from family and friends, usually for a birth or other personal event.</p>
            <p>If you’ve made a contribution through our platform, you may see a charge labeled HELLOBB.NET on your bank or card statement.</p>
            <p>All lists are password-protected and managed privately by their owner.</p>
            <p>You can review our Terms and Conditions for contributors in your preferred language:
            <a href="https://www.hellobb.net/hucha-legal-aportado" target="_blank" rel="noopener noreferrer">Español</a> |
            <a href="https://www.hellobb.net/cagnotte-legal-contributeurs" target="_blank" rel="noopener noreferrer">Français</a> |
            <a href="https://www.hellobb.net/salvadanaio-legal-contributori" target="_blank" rel="noopener noreferrer">Italiano</a></p>
            <p>If you have any questions about a charge or need further assistance, feel free to contact us via our support form or by email at <a href="mailto:soporte@hellobb.net">hola@hellobb.net</a>.</p>    
            <p>Thank you for supporting someone special with your contribution 💛</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "WebSiteContributions",
    metaInfo() {
      return {
        title: "Web Site Contributions",
        meta: [
          { name: "description", content: "Web Site Contributions" },
          { name: "robots", content: "noindex"}
        ],
      };
    },
    computed: {
      lang() {
        return this.$route.meta?.lang || this.$i18n.locale;
      }
    },
  };
  </script>